<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 6C12.2652 6 12.5196 5.89464 12.7071 5.70711C12.8946 5.51957 13 5.26522 13 5V3C13 2.73478 12.8946 2.48043 12.7071 2.29289C12.5196 2.10536 12.2652 2 12 2C11.7348 2 11.4804 2.10536 11.2929 2.29289C11.1054 2.48043 11 2.73478 11 3V5C11 5.26522 11.1054 5.51957 11.2929 5.70711C11.4804 5.89464 11.7348 6 12 6Z"
      fill="currentColor"/>
    <path
      d="M21 11H19C18.7348 11 18.4804 11.1054 18.2929 11.2929C18.1054 11.4804 18 11.7348 18 12C18 12.2652 18.1054 12.5196 18.2929 12.7071C18.4804 12.8946 18.7348 13 19 13H21C21.2652 13 21.5196 12.8946 21.7071 12.7071C21.8946 12.5196 22 12.2652 22 12C22 11.7348 21.8946 11.4804 21.7071 11.2929C21.5196 11.1054 21.2652 11 21 11Z"
      fill="currentColor"/>
    <path
      d="M6 12C6 11.7348 5.89464 11.4804 5.70711 11.2929C5.51957 11.1054 5.26522 11 5 11H3C2.73478 11 2.48043 11.1054 2.29289 11.2929C2.10536 11.4804 2 11.7348 2 12C2 12.2652 2.10536 12.5196 2.29289 12.7071C2.48043 12.8946 2.73478 13 3 13H5C5.26522 13 5.51957 12.8946 5.70711 12.7071C5.89464 12.5196 6 12.2652 6 12Z"
      fill="currentColor"/>
    <path
      d="M6.21999 5.00001C6.02505 4.81568 5.76488 4.71634 5.4967 4.72385C5.22852 4.73135 4.97431 4.84507 4.78999 5.04001C4.60566 5.23494 4.50632 5.49512 4.51382 5.76329C4.52133 6.03147 4.63505 6.28568 4.82999 6.47001L6.26999 7.86001C6.36662 7.95332 6.48107 8.0262 6.60649 8.07431C6.73191 8.12242 6.86574 8.14476 6.99999 8.14001C7.13469 8.13949 7.2679 8.11177 7.39163 8.0585C7.51535 8.00523 7.62704 7.92751 7.71999 7.83001C7.90624 7.64265 8.01078 7.38919 8.01078 7.12501C8.01078 6.86082 7.90624 6.60737 7.71999 6.42001L6.21999 5.00001Z"
      fill="currentColor"/>
    <path
      d="M17 8.13999C17.2575 8.13897 17.5046 8.03867 17.69 7.85999L19.13 6.46999C19.3057 6.28644 19.4049 6.04291 19.4075 5.78886C19.4101 5.53481 19.3159 5.28929 19.1441 5.10216C18.9722 4.91503 18.7356 4.80034 18.4822 4.78136C18.2289 4.76238 17.9778 4.84055 17.78 4.99999L16.34 6.41999C16.1537 6.60735 16.0492 6.86081 16.0492 7.12499C16.0492 7.38918 16.1537 7.64263 16.34 7.82999C16.5131 8.01271 16.7488 8.12341 17 8.13999Z"
      fill="currentColor"/>
    <path
      d="M12 18C11.7348 18 11.4804 18.1054 11.2929 18.2929C11.1054 18.4804 11 18.7348 11 19V21C11 21.2652 11.1054 21.5196 11.2929 21.7071C11.4804 21.8946 11.7348 22 12 22C12.2652 22 12.5196 21.8946 12.7071 21.7071C12.8946 21.5196 13 21.2652 13 21V19C13 18.7348 12.8946 18.4804 12.7071 18.2929C12.5196 18.1054 12.2652 18 12 18Z"
      fill="currentColor"/>
    <path
      d="M17.73 16.14C17.5391 15.9557 17.2827 15.8548 17.0173 15.8595C16.752 15.8642 16.4993 15.9741 16.315 16.165C16.1307 16.356 16.0298 16.6123 16.0345 16.8777C16.0391 17.1431 16.1491 17.3957 16.34 17.58L17.78 19C17.9654 19.1787 18.2125 19.279 18.47 19.28C18.604 19.2808 18.7368 19.2547 18.8605 19.2031C18.9842 19.1516 19.0962 19.0757 19.19 18.98C19.2837 18.8871 19.3581 18.7765 19.4089 18.6546C19.4597 18.5327 19.4858 18.402 19.4858 18.27C19.4858 18.138 19.4597 18.0073 19.4089 17.8855C19.3581 17.7636 19.2837 17.653 19.19 17.56L17.73 16.14Z"
      fill="currentColor"/>
    <path
      d="M6.26998 16.14L4.82998 17.53C4.73625 17.623 4.66186 17.7336 4.61109 17.8554C4.56032 17.9773 4.53418 18.108 4.53418 18.24C4.53418 18.372 4.56032 18.5027 4.61109 18.6246C4.66186 18.7464 4.73625 18.857 4.82998 18.95C4.92377 19.0457 5.03584 19.1216 5.15952 19.1731C5.28321 19.2246 5.41599 19.2508 5.54998 19.25C5.79649 19.2521 6.03509 19.1631 6.21998 19L7.65998 17.61C7.85093 17.4257 7.96085 17.173 7.96553 16.9077C7.97022 16.6423 7.8693 16.3859 7.68498 16.195C7.50065 16.004 7.24802 15.8941 6.98266 15.8894C6.71729 15.8848 6.46093 15.9857 6.26998 16.17V16.14Z"
      fill="currentColor"/>
    <path
      d="M12 8C11.2089 8 10.4355 8.2346 9.77772 8.67412C9.11993 9.11365 8.60723 9.73836 8.30448 10.4693C8.00173 11.2002 7.92252 12.0044 8.07686 12.7804C8.2312 13.5563 8.61216 14.269 9.17157 14.8284C9.73098 15.3878 10.4437 15.7688 11.2196 15.9231C11.9956 16.0775 12.7998 15.9983 13.5307 15.6955C14.2616 15.3928 14.8864 14.8801 15.3259 14.2223C15.7654 13.5645 16 12.7911 16 12C16 10.9391 15.5786 9.92172 14.8284 9.17157C14.0783 8.42143 13.0609 8 12 8ZM12 14C11.6044 14 11.2178 13.8827 10.8889 13.6629C10.56 13.4432 10.3036 13.1308 10.1522 12.7654C10.0009 12.3999 9.96126 11.9978 10.0384 11.6098C10.1156 11.2219 10.3061 10.8655 10.5858 10.5858C10.8655 10.3061 11.2219 10.1156 11.6098 10.0384C11.9978 9.96126 12.3999 10.0009 12.7654 10.1522C13.1308 10.3036 13.4432 10.56 13.6629 10.8889C13.8827 11.2178 14 11.6044 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14Z"
      fill="currentColor"/>
  </svg>
</template>

<script>
  export default {
    name: "SunIcon"
  }
</script>

<style scoped>

</style>
