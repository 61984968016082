<template>
    <div  class="flex flex-col items-center">
        <slot name="name"></slot>
        <div class="flex items-center">
            <slot name="metric"></slot>
            <slot name="icon"></slot>
        </div>
    </div>
</template>
<script>
export default {
  name: 'Counter',
}
</script>
