<template>
  <div class="w-4/5 mr-3">
    <div class="flex flex-col items-center justify-center bg-darkSmoke rounded-lg p-6 h-56 w-full">
      <div class="flex items-center">
        <h5 class="text-5xl font-bold opacity-50 line-through">{{ dayStreaks - 1 }}</h5>
        <h5 class="text-6xl font-bold mx-6">{{ dayStreaks }}</h5>
        <h5 class="text-5xl font-bold opacity-50">{{ dayStreaks + 1 }}</h5>
      </div>
      <span class="uppercase font-bold">day streak</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "StreakCard",

  props: {
    dayStreaks: Number,
  }
}
</script>

<style scoped>

</style>
