<template>
  <ion-page>
    <ion-content>
      <div class="h-56 bg-blue">
        <button @click="closeModal" type="button" class="mt-4 ml-4">
          <ArrowLeftIcon />
        </button>
      </div>
      <div class="relative">
        <template v-if="working">
          <div class="my-16 flex items-center justify-center">
            <ion-spinner name="dots"></ion-spinner>
          </div>
        </template>

        <template v-else>
          <template v-if="! exercise">
            <div class="my-16 flex items-center justify-center">
              This exercise doesn't exist.
            </div>
          </template>

          <template v-if="exercise">
            <!--Video-->
            <div
              class="video-height absolute flex justify-center items-center inset-x-0 pb-full px-8 bg-green-200 bg-opacity-50 -mt-34">
              <ion-spinner color="light" v-show="!this.playing" class="absolute"
                name="lines"></ion-spinner>
              <video id="longVideo" ref="longVideo" @click="toggleLongVideoPlay"
                class="rounded-lg min-w-full min-h-full bg-black w-full h-full object-cover"
                :poster="thumbnail" @play="playing = true"
                @pause="playing = false" muted autoplay webkit-playsinline
                playsinline loop>
                <source :src="exercise.long_video_url" type='video/mp4'>
              </video>

            </div>
            <div class="px-8">
              <div class="pt-24 hlg:pt-40 mb-5 hlg:mb-12">
                <h2 class="mb-3">{{ exercise.title }}</h2>
                <div class="clamp-5" @click="showPopoverDescription = true"
                  v-html="exercise.body"></div>
              </div>
              <div class="grid grid-cols-2 gap-x-5">
                <button v-if="exercise.vote != -1" @click.prevent="downvote"
                  class="flex items-center justify-center px-4 py-3 rounded-full bg-white text-lighPurple w-full flex-shrink-0">
                  <DislikeIcon class="text-lightPurple mr-3" />
                  <span class="text-xs font-bold">Down Vote</span>
                </button>
                <button v-else @click.prevent="clearVote"
                  class="flex items-center justify-center px-4 py-3 rounded-full bg-lightPurple text-lighPurple w-full flex-shrink-0">
                  <DislikeIcon class="text-lightPurple mr-3" />
                  <span class="text-xs font-bold text-white">Down Vote</span>
                </button>

                <button v-if="exercise.vote != 1" @click.prevent="upvote"
                  class="flex items-center justify-center px-4 py-3 rounded-full bg-white text-lighPurple w-full flex-shrink-0">
                  <LikeIcon class="text-lightPurple mr-3" />
                  <span class="text-xs font-bold">Up Vote</span>
                </button>
                <button v-else @click.prevent="clearVote"
                  class="flex items-center justify-center px-4 py-3 rounded-full bg-lightPurple text-lightPurple w-full flex-shrink-0">
                  <LikeIcon class="mr-3" />
                  <span class="text-xs font-bold text-white">Up Vote</span>
                </button>
              </div>
            </div>
          </template>
        </template>
      </div>
      <transition
        enter-active-class="transition ease-out duration-300 transform"
        enter-class="opacity-0 scale-95 translate-y-10"
        enter-to-class="opacity-100 scale-100 translate-y-0"
        leave-active-class="transition ease-in duration-150 transform"
        leave-class="opacity-100 scale-100 translate-y-0"
        leave-to-class="opacity-0 scale-95 translate-y-10">
        <template v-if="showPopoverDescription">
          <div @click="showPopoverDescription = false"
            class="description-overlay absolute inset-0 bg-black bg-opacity-25 flex items-center justify-center p-6 pb-24 max-h-screen overflow-hidden">
            <div @click.stop class="flex-1 bg-white p-8 rounded-lg">
              <h2 class="mb-6 text-2xl">{{ exercise.title }}</h2>
              <div class="overflow-y-scroll" v-html="exercise.body"
                style="max-height: 60vh"></div>
              <div class="mt-8 flex items-center justify-center">
                <button @click.prevent="showPopoverDescription = false"
                  type="button"
                  class="text-sm text-lightPurple uppercase font-bold tracking-widest">
                  Close
                </button>
              </div>
            </div>
          </div>
        </template>
      </transition>
    </ion-content>
  </ion-page>
</template>

<script>
import axios from "axios";
import DislikeIcon from "@/components/svg/DislikeIcon";
import LikeIcon from "@/components/svg/LikeIcon";
import ArrowLeftIcon from "@/components/svg/ArrowLeftIcon";
export default {
  name: "ExerciseDetailsModal",

  components: { ArrowLeftIcon, LikeIcon, DislikeIcon },

  data() {
    return {
      working: false,
      showPopoverDescription: false,
      playing: false,
      muted: true,
    };
  },

  methods: {
    closeModal() {
      return this.$ionic.modalController.dismiss();
    },

    toggleLongVideoPlay() {
      const video = this.$refs.longVideo;

      if (video.paused) {
        return video.play();
      }

      video.pause();
    },
    toggleAudio() {
      if (!this.playing) {
        return;
      }

      this.muted = !this.muted;
    },

    upvote() {
      this.$set(this.exercise, "vote", 1);
      axios.post(`/exercises/${this.exercise.id}/upvote`);
    },

    downvote() {
      this.$set(this.exercise, "vote", -1);
      axios.post(`/exercises/${this.exercise.id}/downvote`);
    },

    clearVote() {
      this.$set(this.exercise, "vote", null);
      axios.post(`/exercises/${this.exercise.id}/clear-vote`);
    },
  },
};
</script>

<style>
.video-height {
  height: 200px;
}

@media (min-height: 812px) {
  .video-height {
    height: 270px;
  }
}
</style>
