<template>
  <ion-page>
    <ion-content>
      <div class="bg h-24 flex justify-end items-start p-5">
        <button @click="closeModal" class="text-white">
          <XIcon class="w-8 h-8"/>
        </button>
      </div>
      <RoundedBigCard class="-mt-8 relative z-10 overflow-hidden">
        <template slot="content" class="flex flex-col">
            <div class="absolute z-0 inset-0">
              <img src="/images/you_did_it.gif" class="w-full opacity-25" alt="">
            </div>
            <div class="relative z-10">
              <!--You did it-->
              <div class="flex flex-col items-center hlg:mt-16 mb-10">
                <h2>
                  Congratulations!
                </h2>
                <p class="text-lightPurple mt-2">
                  New Badge!
                </p>

                <div class="mt-8">
                  <img class="w-auto h-52" :src="awardedBadge.icon_url" />
                </div>

                <div class="mt-8 flex justify-center">
                  <span class="bg-custom-orange-600 py-2 px-4 text-white rounded-full">
                    <span class="font-bold">{{ stats.completed_workouts }} DFG</span> Completed
                  </span>
                </div>

                <div class="mt-8 w-full px-4 justify-center flex">
                  <div class="w-8 h-8" @click.prevent="shareViaTwitter">
                    <TwitterIcon fill="#7E6A97"/>
                  </div>

                  <div class="w-8 h-8 mx-2" @click.prevent="shareViaFacebook">
                    <FacebookBadgesIcon fill="#7E6A97"/>
                  </div>

                  <div class="w-8 h-8" @click.prevent="shareViaInstagram">
                    <InstagramIcon fill="#7E6A97"/>
                  </div>
                </div>

                <div class="mt-4 w-full px-4">
                  <button @click.prevent="collectBadge"
                    class="bg-orange-gradient text-white shadow-orange w-full leading-9 rounded-full py-3 font-bold text-normal"
                    :class="{'opacity-75': collecting}"
                    :disabled="collecting"
                  >
                    {{ collecting ? 'Collecting..' : 'Collect' }}
                  </button>
                </div>
              </div>
            </div>
        </template>
      </RoundedBigCard>
    </ion-content>
  </ion-page>
</template>

<script>
import RoundedBigCard from "@/components/shared/RoundedBigCard";
import XIcon from "@/components/svg/XIcon";
import TwitterIcon from "@/components/svg/TwitterIcon.vue";
import FacebookBadgesIcon from "@/components/svg/FacebookBadgesIcon.vue";
import InstagramIcon from "@/components/svg/InstagramIcon.vue";
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { mapState, mapActions } from 'vuex';
import { STORE_CELEBRATION } from '@/store/types/actions'

export default {
  name: "BadgeAwardModal",
  
  components: {
    XIcon,
    RoundedBigCard,
    TwitterIcon,
    FacebookBadgesIcon,
    InstagramIcon
  },

  data() {
    return {
      collecting: false,
    }
  },

  computed: {
    badges() {
      return this.$store.state.badges
    },

    awardedBadge() {
      return this.badges.find(badge => badge.level == this.stats.level)
    },

    ...mapState({
      user: state => state.auth.user
    })
  },

  methods: {

    ...mapActions({
        sendCelebration: `auth/${STORE_CELEBRATION}`
    }),

    closeModal() {
      return this.$ionic.modalController.dismiss();
    },

    onSharingSuccess(platform){
      this.sendCelebration(platform)
      .then(() => {
        this.closeModal()
      })
      .catch(error => {
        console.log(error);
      })
    },

    onSharingError(ex){
      console.log('Failed', ex)
    },

    shareViaTwitter(){
      this.socialSharing = new SocialSharing()
      const message = `${this.user.name} just won a new badge on Daily Feel Goods! \n Try Daily Feel Goods today!`;

      this.socialSharing.shareViaTwitter(message, this.awardedBadge.icon_url, null)
      .then(() => {
        this.onSharingSuccess('twitter')
      }).catch((error) => {
        this.onSharingError(error);
      });
    },

    shareViaFacebook(){
      this.socialSharing = new SocialSharing()
      const message = `${this.user.name} just won a new badge on Daily Feel Goods! \n Try Daily Feel Goods today!`;
      
      this.socialSharing.shareViaFacebook(message, null, null)
      .then(() => {
        this.onSharingSuccess('facebook')
      }).catch((error) => {
        this.onSharingError(error);
      });
    },

    shareViaInstagram(){
      this.socialSharing = new SocialSharing()
      const message = `${this.user.name} just won a new badge on Daily Feel Goods! \n Try Daily Feel Goods today!`;

      this.socialSharing.shareViaInstagram(message, null, null)
      .then(() => {
        this.onSharingSuccess('instagram')
      }).catch((error) => {
        this.onSharingError(error);
      });
    },

    collectBadge() {
      this.collecting = true

      this.$store
        .dispatch('auth/collectBadge', this.awardedBadge)
        .then(() => {
          this.closeModal()
        })
        .catch(() => {
          this.collecting = false
          this.showErrorToast()
        })
    },

    showErrorToast() {
      this.$ionic
        .toastController.create({
          color: 'danger',
          message: 'There was an error while trying to collect your badge',
          duration: 3000,
        })
        .then(toast => toast.present())
    }
  }
}
</script>

<style scoped>
  .border {
    border: 1px solid transparent;
  }
</style>
