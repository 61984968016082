<template>
  <div class="rounded-full flex items-center justify-center bg-lightPurple py-3 space-x-2">
      <slot >
      </slot>
    <span class="text-white font-bold text-sm uppercase ">{{text}}</span>
  </div>
</template>

<script>

export default {
  name: "ButtonModals",
  props: {
      text: {
          type: String,
          default: "got it"
      },
  },
  components: {
      
  },
};
</script>

<style>
</style>