<template>
  <div :class="selected === true ? 'opacity-100' : 'opacity-50'"
       class="border border-darkViolet rounded-lg p-2 cursor-pointer relative">
    <span v-if="selected === true"
          class="rounded-full bg-darkViolet flex justify-center items-center w-5 h-5 text-white absolute right-0 top-0 mr-3 mt-3">
      <TickIcon/>
    </span>
    <slot name="icon"></slot>
    <span class="font-bold">{{ name }}</span>
  </div>
</template>
<script>

import TickIcon from "@/components/svg/TickIcon";

export default {
  name: 'CustomizeItem',
  components: {TickIcon},
  props: {
    name: String,
    selected: {type: Boolean, default: false}
  }
}
</script>
