<template>
  <div class="absolute">
    <div
      class="relative flex flex-col justify-center items-center inset-x-0 pb-full px-8 bg-green-200 bg-opacity-50 -mt-30 hlg:-mt-20">
      <div v-if="showShortVideo" class="relative w-full z-10">
        <button @click.prevent="showModal"
          class="absolute px-2 py-2 bg-black bg-opacity-50 rounded-t-lg flex justify-between items-center w-full">
          <h1 class="text-white font-bold pl-2">{{ exercise.title }}</h1>
          <div>
            <InformationIcon />
          </div>
        </button>
      </div>
      <div v-else-if="!start" class="relative w-full z-10">
        <button @click.prevent="showModal"
          class="absolute px-2 py-2 bg-black bg-opacity-50 rounded-t-lg flex justify-between items-center w-full">
          <h1 class="text-white font-bold pl-2">{{ exercise.title }}</h1>
          <div>
            <InformationIcon />
          </div>
        </button>
      </div>
      <div
        :class="!start ? 'flex justify-center items-center video-height' : ''"
        class="video-height w-full flex justify-center">
        <ion-spinner color="light" v-show="!this.playing && !start"
          class="absolute" name="lines"></ion-spinner>
        <transition name="fade">
          <video @play="playing = true" @pause="playing = false"
            v-if="start && showShortVideo && short_video_url" id="shortVideo"
            ref="shortVideo" key="short-video"
            class="rounded-lg bg-black w-screen h-full object-cover expanded"
            muted autoplay loop webkit-playsinline playsinline>
            <source :src="short_video_url" />
          </video>
        </transition>
        <div
          class="flex absolute justify-center items-center mt-40 video-height">
          <ion-spinner color="light" v-show="!this.playing && start"
            class="absolute" name="lines"></ion-spinner>
        </div>
        <template v-if="long_video_url !== null">
          <video v-if="!start" @play="playing = true" @pause="playing = false" 
            id="longVideo" ref="longVideo" key="long-video"
            class="rounded-lg min-w-full min-h-full bg-black w-full h-full object-cover"
            @click="toggleAudio" :poster="longVideoThumbnail" muted autoplay
            webkit-playsinline playsinline loop>
          <source :src="long_video_url" type='video/mp4'>
          </video>
        </template>

        <transition name="simple-fade" appear>
          <div v-if="showSoundFeedback" class="absolute">
            <div class="p-4 rounded-lg bg-black bg-opacity-50 text-white">
              <SoundOnIcon v-if="!muted" class="w-10 h-10" />
              <SoundOffIcon v-else class="w-10 h-10" />
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Plugins,
  FilesystemDirectory,
  FilesystemEncoding,
} from "@capacitor/core";

const { Filesystem } = Plugins;

import InformationIcon from "@/components/svg/InformationIcon.vue";
import SoundOnIcon from "@/components/svg/SoundOnIcon";
import SoundOffIcon from "@/components/svg/SoundOffIcon";

import getUrlFilename from "@/utils/getUrlFilename";

export default {
  name: "VideoComponent",

  props: {
    start: {
      default: false,
      type: Boolean,
    },

    exercise: {
      type: Object,
      default: () => ({}),
    },

    showShortVideo: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      playing: false,
      muted: true,
      showSoundFeedback: false,
      short_video_url: null,
      long_video_url: null,
    };
  },

  components: {
    InformationIcon,
    SoundOnIcon,
    SoundOffIcon,
  },

  mounted: async function () {
    try {
      let contents = await Filesystem.readFile({
        path: `short_videos/${getUrlFilename(this.exercise.short_video_url)}`,
        directory: FilesystemDirectory.External,
        encoding: FilesystemEncoding.UTF8,
        recursive: true,
      });

      this.short_video_url = contents.data;
    } catch (ex) {
      this.short_video_url = this.exercise.short_video_url;
    }
    
    try {
      let contents = await Filesystem.readFile({
        path: `long_videos/${getUrlFilename(this.exercise.long_video_url)}`,
        directory: FilesystemDirectory.External,
        encoding: FilesystemEncoding.UTF8,
        recursive: true,
      });

      this.long_video_url = contents.data;
    } catch (ex) {
      this.long_video_url = this.exercise.long_video_url;
    }
  },

  computed: {
    videoHeight() {
      if (this.start === true) {
        return "expanded";
      } else {
        return null;
      }
    },

    longVideoThumbnail: function () {
      return this.exercise.long_video_thumbnail !== null
        ? this.exercise.long_video_thumbnail_url
        : "/images/default-thumb.png";
    },
  },

  watch: {
    start(start) {
      if (!start) {
        return;
      }
    },
    muted(muted) {
      this.showSoundFeedback = true;
      this.$refs.longVideo.muted = muted;

      setTimeout(() => {
        this.showSoundFeedback = false;
      }, 750);
    },
  },

  methods: {
    toggleAudio() {
      if (!this.playing) {
        return;
      }

      this.muted = !this.muted;
    },
    toggleLongVideoPlay() {
      const video = this.$refs.longVideo;

      if (video.paused) {
        return video.play(), (this.playing = true);
      }

      video.pause();
    },

    showModal() {
      this.$emit("openInfo");
    },
  },
};
</script>

<style>
.video-height {
  height: 200px;
  /*top: -20%;*/
  transition: all ease 0.3s;
}

.expanded {
  height: 75vh !important;
}

@media (min-height: 812px) {
  .video-height {
    height: 220px;
    top: 4%;
  }

  .expanded {
    height: 75vh !important;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
