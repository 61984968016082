<template>
  <ion-page class="">
    <ion-content  :scroll-y="false" :fullscreen="true">
      <ion-scroll direction="xy" style="width: 500px; height: 500px"> 
      <ModalHeader :close-modal="closeModal"/>
      <RoundedBigCard   class="-mt-8 relative z-10 small-device">
        <template slot="header">
          <div class="w-full flex justify-between items-centers mb-3">
            <h2>Customize</h2>
            <div
              @click.prevent="selectAll"
              class="select-none text-xs text-lightPurple border rounded-full border-lightPurple px-3 flex items-center flex-shrink-0 cursor-pointer">
              Select all
            </div>
          </div>
          <p>What areas do you want to focus on?</p>
        </template>
        <template slot="content">
          <div class="grid grid-cols-2 gap-5 mt-6">
            <label>
              <CustomizeItem :selected="selectedAreas.includes('Mid Back')" name="Mid Back" key="MidBack">
                <template slot="icon">
                  <MidBack class="mx-auto mb-3"/>
                </template>
              </CustomizeItem>
              <input type="checkbox" class="hidden" v-model="selectedAreas" value="Mid Back">
            </label>
            <label>
              <CustomizeItem :selected="selectedAreas.includes('Lower Back')" name="Lower Back" key="LowerBack">
                <template slot="icon">
                  <LowerBack class="mx-auto mb-3"/>
                </template>
              </CustomizeItem>
              <input type="checkbox" class="hidden" v-model="selectedAreas" value="Lower Back">
            </label>
            <label>
              <CustomizeItem :selected="selectedAreas.includes('Knee')" name="Knee" key="Knee">
                <template slot="icon">
                  <Knee class="mx-auto mb-3"/>
                </template>
              </CustomizeItem>
              <input type="checkbox" class="hidden" v-model="selectedAreas" value="Knee">
            </label>
            <label>
              <CustomizeItem  :selected="selectedAreas.includes('Ankle')" name="Ankle" key="Ankle">
                <template slot="icon">
                  <Ankle class="mx-auto mb-3"/>
                </template>
              </CustomizeItem>
              <input type="checkbox" class="hidden" v-model="selectedAreas" value="Ankle">
            </label>
            <label>
              <CustomizeItem :selected="selectedAreas.includes('Hip')" name="Hip" key="Hip">
                <template slot="icon">
                  <Hip class="mx-auto mb-3"/>
                </template>
              </CustomizeItem>
              <input type="checkbox" class="hidden" v-model="selectedAreas" value="Hip">
            </label>
            <label>
              <CustomizeItem :selected="selectedAreas.includes('Shoulder')" name="Shoulder" key="Shoulder">
                <template slot="icon">
                  <Shoulder class="mx-auto mb-3"/>
                </template>
              </CustomizeItem>
              <input type="checkbox" class="hidden" v-model="selectedAreas" value="Shoulder">
            </label>
            <label class="col-span-2 w-1/2 mx-auto">
              <CustomizeItem :selected="selectedAreas.includes('Neck')" name="Neck" key="Neck">
                <template slot="icon">
                  <Neck class="mx-auto mb-3"/>
                </template>
              </CustomizeItem>
              <input type="checkbox" class="hidden" v-model="selectedAreas" value="Neck">
            </label>
          </div>
          <div v-if="error" class="mt-4 bg-red-100 p-4 rounded-lg shadow-md text-red-700">
            {{ error }}
          </div>
          <button type="button" @click.prevent="generateWorkout" class="bg-lightPurple text-white w-full text-base mt-8 mb-3 font-bold py-3 rounded-full text-lg leading-8 disabled:opacity-75" :disabled="working">
            Generate FeelGoods
          </button>
        </template>
      </RoundedBigCard>
      </ion-scroll>
    </ion-content>
  </ion-page>
</template>

<script>

import { mapActions, mapState } from 'vuex';

import { UPDATE_AREAS } from '@/store/types/actions';

import RoundedBigCard from "@/components/shared/RoundedBigCard";
import ModalHeader from "@/components/shared/modals/CustomizeModal/partials/ModalHeader";
import CustomizeItem from "@/components/shared/modals/CustomizeModal/partials/CustomizeItem";
import * as Icons from "@/components/svg/customize";


export default {
  name: "index",


  components: {
    CustomizeItem, 
    ModalHeader, 
    RoundedBigCard, 
    ...Icons,
  },

  data() {
    return {
      working: false,

      error: null,

      selectedAreas: [],

      availableAreas: [
        'Mid Back', 
        'Lower Back',
        'Knee',
        'Ankle',
        'Hip',
        'Shoulder',
        'Neck',
      ],
    }
  },

  mounted: function() {
    this.selectedAreas = this.areas;
  },

  methods: {
    ...mapActions({
      updateAreas: `auth/${UPDATE_AREAS}`
    }),

    closeModal() {
      return this.$ionic.modalController.dismiss();
    },

    selectAll() {
      this.selectedAreas = [...this.availableAreas]
    },

    async generateWorkout() {
      this.working = true
      this.error = null
      
      try {
        await this.$store.dispatch('workout/fetchWorkout', {
          areas: this.selectedAreas,
          fresh: true,
        })  

        this.closeModal()
      } catch(error) {
        this.error = error.response.data.errors.error 
        this.working = false
      }
    }
  },

  computed: {
    ...mapState({
      areas: state => state.auth.user.metadata ? state.auth.user.metadata.areas : []
    })
  },

  watch: {
    selectedAreas: function(areas) {
      this.updateAreas(areas);
    }
  }
}
</script>

<style>
.small-device {
  height: 600px !important;
}

@media (min-height: 812px) {
  .small-device {
    height: inherit !important;
  }
}
#scroll-list{
height: 200px;
}
</style>

