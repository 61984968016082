<template>
  <span v-if="progress.workout.completed"
      class="rounded-full h-6 w-6 bg-customOrange flex justify-center items-center text-white block mt-3">
    <TickIcon/> 
  </span>
  <span v-else-if="progress.weekday > currentWeekday"
      class="rounded-full border-2 h-6 w-6 flex justify-center items-center text-white opacity-50 block mt-3">
    <TickIcon class="text-gray-100" />
  </span>
  <div v-else
       class="rounded-full relative h-8 w-8 flex justify-center items-center not-completed-bg mt-2">
    <span class="text-xxs">{{ progress.workout.finished_exercises }}</span>
    <span class="text-xxs">/</span>
    <span class="text-xxs">{{ progress.workout.exercises }}</span>
    <div ref="dayProgress" class="day-progress absolute inset-0"></div>
  </div>
</template>
<script>
import ProgressBar from 'progressbar.js'
import TickIcon from "@/components/svg/TickIcon"

export default {
  name: 'Status',
  
  props: {
    progress: Object
  },

  components: {
    TickIcon
  },

  data() {
    return {
      dayProgress: null,
    }
  },

  computed: {
    currentWeekday() {
      return (new Date()).getDay()
    },

    isComplete() {
      return this.progress.workout.completed
    },

    percentage() {
      return this.progress.workout.finished_exercises / this.progress.workout.exercises
    }
  },

  methods: {
    showDayProgress() {
      this.$nextTick().then(() => {
        if (this.dayProgress) {
          this.dayProgress.destroy()
        }

        this.dayProgress = new ProgressBar.Circle(this.$refs.dayProgress, {
          color: '#FD5C19',
          trailColor: '#2F1F38',
          duration: 1500,
          strokeWidth: 6,
        })

        this.dayProgress.animate(this.percentage)
      })
    },
  },

  mounted() {
    if (! this.isComplete && this.progress.weekday <= this.currentWeekday) {
      this.showDayProgress()
    }
  }
}
</script>
<style scoped>
.not-completed-bg {
  background-color: #2F1F38 !important;
}
</style>
