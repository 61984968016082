<template>
  <div class="px-8 pt-30 hlg:pt-56 mb-5 hlg:mb-12">
    <div class="flex items-center justify-between leading-none">
      <h2 class="text-2.5xl">{{ exercise.title }}</h2>
      <div>
        <button @click.prevent="openExerciseModal" type="button" class="flex items-center justify-center p-1">
          <svg class="h-6 w-6 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </button>
      </div>
    </div>
    
    <div class="flex mt-2 items-center w-full space-x-2">

      <template v-if="workout.type.name == 'Tabatta'">
        <span class="text-xs rounded-full bg-white text-darkViolet px-3 uppercase tracking-widest">
          {{ workout.params.sets }} {{ workout.params.sets == 1 ? 'set' : 'sets' }}
        </span>
        <span class="text-xs rounded-full bg-white text-darkViolet px-3 uppercase tracking-widest">
          {{ workout.params.duration }} secs / set
        </span>
      </template>

      <template v-else-if="workout.type.name == 'Superset'">
        <span class="text-xs rounded-full bg-white text-darkViolet px-3 uppercase tracking-widest">
          {{ workout.params.sets }} {{ workout.params.sets == 1 ? 'set' : 'sets' }}
        </span>

        <span v-if="exercise.symmetry == 'unilateral'" class="text-xs rounded-full bg-white text-darkViolet px-3 uppercase tracking-widest">
          {{ workout.params.reps.unilateral }} reps / side / set
        </span>
        <span v-else class="text-xs rounded-full bg-white text-darkViolet px-3 uppercase tracking-widest">
          {{ workout.params.reps.bilateral }} reps / set
        </span>
      </template>

      <template v-else>
        <span class="text-xs rounded-full bg-white text-darkViolet px-3 uppercase tracking-widest">
          {{ workout.params.sets }} {{ workout.params.sets == 1 ? 'set' : 'sets' }}
        </span>
        
        <span v-if="exercise.symmetry == 'unilateral'" class="text-xs rounded-full bg-white text-darkViolet px-3 uppercase tracking-widest">
          {{ workout.params.reps.unilateral }} secs / side
        </span>
        <span v-else class="text-xs rounded-full bg-white text-darkViolet px-3 uppercase tracking-widest">
          {{ workout.params.reps.bilateral }} secs
        </span>
      </template>
    </div>
    
    <div class="max-h-17 h-full overflow-hidden mt-4" v-html="exercise.body"></div>
  </div>
</template>
<script>
import ExerciseDetailsModal from '@/components/shared/modals/ExerciseDetailsModal'
export default {
  props: {
    exercise: {
      type: Object,
      default: () => ({})
    },

    workout: Object,
  },

  name: 'ExerciseInfo',

  methods: {
    openExerciseModal() {
      return this.$ionic.modalController
        .create({
          component: ExerciseDetailsModal,
          componentProps: {
            parent: this.$root,
            data: {
              exercise: this.exercise,
            }
          }
        })
        .then(modal => modal.present())
    }
  }
}
</script>
