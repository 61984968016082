<template>
  <div class="w-4/5 mr-3">
    <div class="flex flex-col items-center justify-center bg-darkSmoke rounded-lg p-6 h-56 w-full">
      <div class="text-center">
        <h5 class="text-6xl font-bold mx-6">+{{ minutes }}</h5>
        <span class="uppercase font-bold">{{ minutes == 1 ? 'minute' : 'minutes' }} <br> of movement</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MinutesCard",

  props: {
    minutes: {
      type: Number,
      default: 1,
    },
  }
}
</script>
