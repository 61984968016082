<template>
  <ion-page>
    <ion-content class="relative hide-overflow" :scroll-y="false"
      :fullscreen="true">
      <div class="pl-8 pb-16 pr-8 pt-6 hlg:pt-12 text-white bg"
        @click="$router.push({ name: 'activity' })">
        <!-- Counters-->
        <ProgressCounter :stats="stats" />
        <!--Week days-->
        <WeekProgress
          :weekProgress="stats ? stats.week_progress : weekProgress" />
      </div>
      <!-- Cards slider-->
      <RoundedBigCard class="-mt-8 h-full" :is-home="true">
        <template slot="header">
          <div class="flex items-center justify-center px-10 pt-8 hlg:pt-10">
            <h3 class="flex-shrink-0">Today’s Feel Goods</h3>
          </div>
          <div
            class="flex items-center justify-center mb-4 hlg:mb-6 px-10 pt-2">
            <div @click="openCustomizeModal"
              class="text-xs py-1 text-customOrange border rounded-full border-customOrange px-2 w-20 mr-3">
              Customize
            </div>
            <div @click="onShuffle"
              class="text-xs py-1 text-customOrange border rounded-full border-customOrange px-2 w-20 flex justify-center">
              Shuffle
            </div>
          </div>
        </template>
        <template slot="content">
          <template v-if="!loading">
            <template
              v-if="workout && workout.exercises && workout.exercises.length">
            
              <div id="flickity" :class="working === false ? 'py-32 ' : ' '" class="relative justify-center items-center flex">
                <flickity class="w-full" ref="flickity" :options="flickityOptions"
                :key="workoutKey">
                
                  <ExerciseCard v-for="(exercise, index) in workout.exercises"
                    :key="`exercise-${exercise.id}`" :exercise="exercise"
                    :workout="workout" :params="workout.params"
                    :exerciseIndex="index" @click="onExerciseCardClick" />
                  
                </flickity>
                <ion-spinner class="absolute" v-show="!working" name="dots"></ion-spinner>
              </div>

              <PlayButton />
              <Wave />
            </template>
            <template v-else>
              <div class="w-full flex justify-center items-center h-48 hlg:h-64">
                <ion-spinner  name="dots"></ion-spinner>
              </div>
            </template>
          </template>
          <template v-else>
            <ion-spinner name="dots" class="m-auto flex"></ion-spinner>
          </template>
        </template>
      </RoundedBigCard>
    </ion-content>
  </ion-page>
</template>

<script>
import Flickity from "vue-flickity";
import { mapActions, mapMutations, mapState } from "vuex";
import { Plugins, FilesystemDirectory, FilesystemEncoding } from '@capacitor/core';

const { Filesystem } = Plugins;

import getUrlFilename from '@/utils/getUrlFilename';

import { EventBus } from "@/utilities/event-bus";
import ProgressCounter from "@/components/home/ProgressCounter/index";
import WeekProgress from "@/components/home/WeekProgress/index";
import RoundedBigCard from "@/components/shared/RoundedBigCard";
import ExerciseCard from "@/components/home/ExerciseCard";
import CustomizeModal from "@/components/shared/modals/CustomizeModal/index";
import PlayButton from "@/components/home/PlayButton";
import Wave from "@/components/home/Wave";
import WorkoutModal from "@/components/shared/modals/WorkoutModal/index";

export default {
  name: "Home",
  components: {
    ExerciseCard,
    RoundedBigCard,
    WeekProgress,
    ProgressCounter,
    Flickity,
    PlayButton,
    Wave,
  },

  data() {
    return {
      flickityOptions: {
        imagesLoaded: true,
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: false,
        destroy: () => {
          console.log("DESTROY");
        }
      },
      working: false,
      loading: false
    };
  },

  mounted: async function () {
    await this.fetchWorkout();

    this.downloadWorkoutExercises();

    setTimeout(() => {
      if (!this.$refs.flickity) {
        return;
      }

      this.$refs.flickity.$flickity.resize();
    }, 250);

    EventBus.$on("openWorkoutModal", () => {
      this.openWorkoutModal();
    });

    this.$refs.flickity.on("change", (index) => {
      this.setCurrentExerciseIndex(index);
    });

    this.working = true;
  },

  beforeDestroy: function() {
    let element = document.getElementById("flickity");

    element.parentElement.parentElement.removeChild(element.parentElement);
  },

  methods: {
    ...mapActions({
      fetchWorkout: "workout/fetchWorkout",
    }),

    ...mapMutations({
      setCurrentExerciseIndex: `workout/SET_CURRENT_EXERCISE_INDEX`,
    }),

    onShuffle: async function () {
      this.error = null;

      try {
        this.loading = true;

        await this.fetchWorkout({
          areas: this.areas,
          fresh: true,
        });

        this.downloadWorkoutExercises();
      } catch (error) {
        this.error = error.response.data.errors.error;
        this.working = false;
      } finally {
        this.loading = false;
      }
    },

    onExerciseCardClick: function (index) {
      this.setCurrentExerciseIndex(index);
    },

    downloadWorkoutExercises: function() {
      this.workout.exercises.forEach(exercise => {
          fetch(exercise.short_video_url)
            .then(resp => resp.blob())
            .then(async (blob) => {
              const reader = new FileReader();
  
              reader.readAsDataURL(blob);
  
              reader.onloadend = async () => {
                  const filename = getUrlFilename(exercise.short_video_url);

                  try {
                    await Filesystem.readFile({
                      path: `short_videos/${filename}`,
                      directory: FilesystemDirectory.External,
                      encoding: FilesystemEncoding.UTF8,
                      recursive: true,
                    });
                    this.downloadLongExercises();
                  } catch (ex) {
                    var base64data = reader.result; 
                    
                    const result = await Filesystem.writeFile({
                      path: `short_videos/${filename}`,
                      data: base64data,
                      directory: FilesystemDirectory.External,
                      encoding: FilesystemEncoding.UTF8,
                      recursive: true
                    });

                    return result;
                  }
              }            
            });
        });
    },

downloadLongExercises: function() {
      this.workout.exercises.forEach(exercise => {
          fetch(exercise.long_video_url)
            .then(resp => resp.blob())
            .then(async (blob) => {
              const reader = new FileReader();
  
              reader.readAsDataURL(blob);
  
              reader.onloadend = async () => {
                  const filename = getUrlFilename(exercise.long_video_url);

                  try {
                    await Filesystem.readFile({
                      path: `long_videos/${filename}`,
                      directory: FilesystemDirectory.External,
                      encoding: FilesystemEncoding.UTF8,
                      recursive: true
                    });
                    
                  } 
                  
                  catch (ex) {
                    var base64data = reader.result; 
                    
                    const result = await Filesystem.writeFile({
                      path: `long_videos/${filename}`,
                      data: base64data,
                      directory: FilesystemDirectory.External,
                      encoding: FilesystemEncoding.UTF8,
                      recursive: true
                    });

                    return result;
                  }
              }            
            });
        });
    },

    openCustomizeModal() {
      return this.$ionic.modalController
        .create({
          component: CustomizeModal,
          componentProps: {
            parent: this,
          },
        })
        .then((m) => m.present());
    },

    openWorkoutModal() {
      return this.$ionic.modalController
        .create({
          component: WorkoutModal,
          swipeToClose: true,
          componentProps: {
            parent: this.$root,
            data: {
              workout: this.workout,
            },
          },
        })
        .then((modal) => modal.present());
    },
  },

  destroyed: function () {
    EventBus.$off("openWorkoutModal");
  },

  computed: {
    ...mapState({
      weekProgress: (state) => state.weekProgress,
      stats: (state) => state.auth.stats,
      workout: (state) => state.workout,
      areas: state => state.auth.user.metadata ? state.auth.user.metadata.areas : []
    }),

    workoutKey: function () {
      if (!this.workout) {
        return "flickity-" + Math.round(Math.random() * 1000);
      }

      return (
        "flickity-" +
        this.workout.exercises.map((exercise) => exercise.id).join("-")
      );
    },
  },
};
</script>


<style scoped>
.hide-overflow {
  --overflow: hidden !important;
}
</style>