<template>
  <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.7849 1.6203L4.99689 8.5317L1.91144 5.39016" stroke="currentColor" stroke-width="1.97469"
          stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: "TickIcon"
  }
</script>

<style scoped>

</style>
