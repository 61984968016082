<template>
  <div
    v-if="exercise"
    class="bg-white rounded-t-xl hlg:px-10 px-5 hlg:py-6 py-4 flex items-center w-full block cursor-pointer">
    <img class="rounded-lg h-16 w-24 object-cover mr-4" :src="exercise.thumbnail_url">
    <div class="font-bold flex flex-col">
      <span class="uppercase text-orange-500">Up Next</span>
      <span class="text-darkPurple">{{ exercise.title }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ModalFooter',

  props: {
    exercise: Object,
  }
}
</script>
