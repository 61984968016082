<template>
  <div class="flex justify-between items-center w-full">
    
    <template v-if="showRest">
      <div key="restProgress" class="flex-1 flex items-center justify-center">
        <div class="w-40 h-40 bg-white rounded-full flex items-center justify-center shadow-xl relative z-20">
          <div class="relative rounded-full w-32 h-32 flex flex-col justify-center items-center">
            <span class="text-xs font-semibold">rest</span>
            <span class="text-3xl font-bold">{{ restCounter }}</span>
            <span class="uppercase text-xs">Secs</span>
            <div ref="restProgress" class="rest-progress absolute inset-0"></div>
          </div>
        </div>
        <transition name="simple-fade" appear>
          <div v-show="showRest" class="fixed inset-0 bg-lightPurple bg-opacity-50 z-10"></div>
        </transition>
      </div>
    </template>

    <template v-else-if="showSwitchSide">
      <div key="switchProgress" class="flex-1 flex items-center justify-center">
        <div class="w-40 h-40 bg-white rounded-full flex items-center justify-center shadow-xl relative z-20">
          <div class="relative rounded-full w-32 h-32 flex flex-col justify-center items-center">
            <span class="text-xs font-semibold">switch side</span>
            <span class="text-3xl font-bold">{{ switchSideCounter }}</span>
            <span class="uppercase text-xs">Secs</span>
            <div ref="switchProgress" class="switch-progress absolute inset-0"></div>
          </div>
        </div>
        <transition name="simple-fade" appear>
          <div v-show="showSwitchSide" class="fixed inset-0 bg-lightPurple bg-opacity-75 z-10"></div>
        </transition>
      </div>
    </template>

    <template v-else-if="showPrepare">
      <div key="prepareProgress" class="flex-1 flex items-center justify-center">
        <div class="w-40 h-40 bg-white rounded-full flex items-center justify-center shadow-xl relative z-20">
          <div class="relative rounded-full w-32 h-32 flex flex-col justify-center items-center">
            <!-- <span v-if="symmetry == 'unilateral'" class="text-xs font-semibold">left side</span> -->
            <span class="text-xs font-semibold">Ready...</span>
            <span class="text-3xl font-bold">Go!</span>
            <!-- <span class="uppercase text-xs">Secs</span> -->
            <div ref="prepareProgress" class="prepare-progress absolute inset-0"></div>
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <!-- <template v-if="type == 'Superset'">
        <button v-if="pace == slowPace" type="button" @click.prevent="resetSpeed" class="bg-lightPurple text-white rounded-r-full pl-3 pr-6 h-15 py-3 flex justify-center items-center shadow-xl ">
          <TurtleIcon/>
        </button>
        <button v-else type="button" @click.prevent="slowDown" class="bg-white text-lightPurple rounded-r-full pl-3 pr-6 h-15 py-3 flex justify-center items-center shadow-xl ">
          <TurtleIcon/>
        </button>
      </template> -->

      <div key="mainProgress" class="flex-1 flex items-center justify-center">
        <div class="w-40 h-40 bg-white rounded-full flex items-center justify-center shadow-xl">
          <div class="relative rounded-full w-32 h-32 flex flex-col justify-center items-center">
            <span v-if="symmetry == 'unilateral'" class="text-xs font-semibold">
              {{ side }} side
            </span>
            <span class="text-3xl font-bold">{{ repsCounter }}</span>
            <span class="uppercase text-xs">{{ type == 'Superset' ? 'Reps' : 'Secs' }}</span>
            <div ref="mainProgress" class="main-progress absolute inset-0"></div>
          </div>
        </div>
      </div>

      <!-- <template v-if="type == 'Superset'">
        <button v-if="pace == fastPace" type="button" @click.prevent="resetSpeed" class="bg-lightPurple text-white rounded-l-full h-15 pr-4 pl-6 py-3 flex justify-center items-center shadow-xl ">
          <HareIcon/>
        </button>
        <button v-else type="button" @click.prevent="speedUp" class="bg-white text-lightPurple rounded-l-full h-15 pr-4 pl-6 py-3 flex justify-center items-center shadow-xl ">
          <HareIcon/>
        </button>
      </template> -->
    </template>
  </div>
</template>
<script>
import { Plugins } from "@capacitor/core";
import ProgressBar from 'progressbar.js'

import {EventBus} from "@/utilities/event-bus"
// import HareIcon from "@/components/svg/HareIcon"
// import TurtleIcon from "@/components/svg/TurtleIcon"

const { NativeAudio } = Plugins;

export default {
  name: 'RepsCountDown',

  props: {
    reps: Number,
    rest: Number,
    symmetry: String,
    type: String,
  },

  // components: {HareIcon, TurtleIcon},

  data() {
    return {
      pace: 1000,
      defaultPace: 1000,
      fastPace: 1000,
      slowPace: 1500,
      repsCounter: 15,
      restCounter: 10,
      showRest: false,
      showSwitchSide: false,
      switchSideCounter: 3,
      side: 'left',
      showPrepare: false,
      prepareCounter: 2,
      start: null,
    }
  },

  computed: {
    canSwitchSides() {
      return this.type != 'Tabatta' && this.symmetry == 'unilateral' && this.side == 'left'
    },

    workout() {
      return this.$store.state.workout
    },
  },

  methods: {
    startCount() {

      try {
         NativeAudio.play({
          assetId: "game-start-countdown-short",
        });
      } catch (ex) {
        console.log(ex);
      }

      this.$emit('start-count');
      this.start = new Date
      clearInterval(this.mainInterval)

      this.startMainProgress(this.repsCounter)

      this.mainInterval = setInterval(() => {
        if (this.repsCounter > 1) {
          this.repsCounter--
        } else {
          clearInterval(this.mainInterval)

          EventBus.$emit('recordMovement', this.calculateSeconds(new Date, this.start))

          if (this.canSwitchSides) {
            return this.switchSide()
          }

          this.startRest()
        }
      }, this.pace)
    },

    startRest() {
      try {
        NativeAudio.play({
          assetId: "stop-sound-exercise",
        });
      } catch (ex) {
        console.log(ex);
      }
      
      if(this.workout.exercises.length === 1) {
        EventBus.$emit('endExercise');
      } else {
        this.showRest = true
        this.restCounter = this.rest || 10
  
        this.startRestProgress(this.restCounter)
        
        this.restInterval = setInterval(() => {
          if (this.restCounter > 1) {
            this.restCounter--
          } else {
            this.showRest == false
            clearInterval(this.restInterval)
            
            // end exercise
            EventBus.$emit('endExercise');
          }
        }, 1000)
      }

    },

    switchSide() {
      this.showSwitchSide = true
      this.side = 'right'

      this.startSwitchProgress(this.switchSideCounter)

      this.switchInterval = setInterval(() => {
        if (this.switchSideCounter > 1) {
          this.switchSideCounter--
        } else {
          clearInterval(this.switchInterval)
          this.repsCounter = this.reps
          this.showSwitchSide = false
          this.startCount()
        }
      }, 1000)
    },

    prepareCount() {
      this.showPrepare = true

      this.startPrepareProgress(this.prepareCounter)

      this.prepareInterval = setInterval(() => {
        if (this.prepareCounter > 1) {
          this.prepareCounter--
        } else {
          clearInterval(this.prepareInterval)
          this.showPrepare = false
          this.startCount()
        }
      }, 1000)
    },

    slowDown() {
      this.pace = this.slowPace
      this.startCount()
    },

    speedUp() {
      this.pace = this.fastPace
      this.startCount()
    },

    resetSpeed() {
      this.pace = this.defaultPace
      this.startCount()
    },

    calculateSeconds(from, to) {
      return Math.round((from.getTime() - to.getTime()) / 1000)
    },

    startMainProgress(duration) {
      this.$nextTick().then(() => {
        if (this.mainProgress) {
          this.mainProgress.destroy()
        }

        this.mainProgress = new ProgressBar.Circle(this.$refs.mainProgress, {
          color: '#ed8936',
          trailColor: '#f7f7f7',
          duration: duration * this.pace,
          strokeWidth: 3
        })
        this.mainProgress.animate(1)
      })
    },

    startRestProgress(duration) {
      this.$nextTick().then(() => {
        if (this.restProgress) {
          this.restProgress.destroy()
        }

        this.restProgress = new ProgressBar.Circle(this.$refs.restProgress, {
          color: '#4077AA',
          trailColor: '#f7f7f7',
          duration: duration * 1000,
          strokeWidth: 3
        })
        this.restProgress.animate(1)
      })
    },

    startSwitchProgress(duration) {
      this.$nextTick().then(() => {
        if (this.switchProgress) {
          this.switchProgress.destroy()
        }

        this.switchProgress = new ProgressBar.Circle(this.$refs.switchProgress, {
          color: '#8CBA52',
          trailColor: '#f7f7f7',
          duration: duration * 1000,
          strokeWidth: 3
        })
        this.switchProgress.animate(1)
      })
    },

    startPrepareProgress(duration) {
      this.$nextTick().then(() => {
        if (this.prepareProgress) {
          this.prepareProgress.destroy()
        }

        this.prepareProgress = new ProgressBar.Circle(this.$refs.prepareProgress, {
          color: '#F2C94C',
          trailColor: '#f7f7f7',
          duration: duration * 1000,
          strokeWidth: 3
        })
        this.prepareProgress.animate(1)
      })
    }
  },

  mounted: async function() {
    this.showPrepare = true;

    try{
      if(this.workout.currentExerciseIndex == 0 && this.workout.currentSet == 1) {
        NativeAudio.play({
          assetId: "game-start-countdown",
        });
      }
    } catch (ex) {
      console.log(ex);
    }

    setTimeout(() => {
      this.showPrepare = false;

      if (this.reps) {
        this.repsCounter = this.reps
      }

      if (this.type == 'Superset') {
        this.pace = 2000
        this.defaultPace = 2000
        this.fastPace = 1500
        this.slowPace = 3000
      }

      if (this.workout.currentExerciseIndex == 0 && this.workout.currentSet == 1) {
        this.prepareCount()
      } else {
        this.startCount()
      }
    }, 1000);

  },

  beforeDestroy: function() {
    try {
      NativeAudio.stop({
        assetId: "game-start-countdown",
      });
    } catch (ex) {
      console.log(ex);
    }
  },

  destroyed() {
    clearInterval(this.mainInterval)
    clearInterval(this.restInterval)
    clearInterval(this.switchInterval)
    clearInterval(this.prepareInterval)
  }
}
</script>
