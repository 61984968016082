<template>
  <img v-if="currentRoute === 'home'" class="absolute bottom-0 z-10 hlg:mb-3 w-full"
       src="@/assets/images/bg-wave.svg" alt="">
</template>
<script>
export default {
  name: 'Wave',
  computed: {
    currentRoute() {
      return this.$route.name;
    }
  }
}
</script>
