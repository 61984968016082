<template>
  <div class="w-8/12 pr-6">
    <div :style="backgroundImage"
         class="h-48 hlg:h-64 rounded-xl w-full relative bg-cover bg-center bg-no-repeat p-5 flex flex-col items-center"
         @click="onExerciseClick">
      <!-- Card header-->
      <div class="w-full flex justify-between items-center">
        <!--Rounds-->
        <span
          class="text-xs rounded-full bg-white text-darkViolet px-3 uppercase tracking-widest">{{ params.sets || 3 }} {{ params.sets == 1 ? 'set' : 'sets' }}</span>
        <!-- Favorite button-->
        <button :id="`favorite-button-${exercise.id}`" class="flex justify-center items-center bg-white rounded-full p-2">
          <HeartIcon class="hidden w-5 h-5 text-custom-orange-500"/>
          
          <svg v-if="exercise.vote == 1" class="w-5 h-5 text-custom-orange-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clipRule="evenodd" />
          </svg>
          <svg v-else class="w-5 h-5 text-custom-orange-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
          </svg>
        </button>
      </div>
      <!--Trainning info-->
      <div class="mt-3">
        <h4 class="text-white text-xl hlg:text-2xl font-bold mb-1"> {{ exercise.title }}</h4>
        <div class="text-xs text-white opacity-75">{{exercise.description | truncate(40, '...')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import HeartIcon from "@/components/svg/HeartIcon";
import WorkoutModal from "@/components/shared/modals/WorkoutModal/index";

export default {
  name: "ExerciseCard",
  
  components: {
    HeartIcon,
  },

  props: {
    exercise: Object,
    params: Object,
    exerciseIndex: Number,
    workout: Object,
  },

  methods: {
    openModal() {
      return this.$ionic.modalController
        .create({
          component: WorkoutModal,
          swipeToClose: true,
          componentProps: {
            parent: this.$root,
            data: {
              workout: this.workout,
            },
          }
        }).then(modal => modal.present())
    },

    onExerciseClick: function(event) {
      let isNestedTarget = document.getElementById(`favorite-button-${this.exercise.id}`).contains(event.target);

      if(!isNestedTarget) {
        this.$emit('click', this.exerciseIndex);
        this.openModal();
      } else {
        this.toggleHeart();
      }
    },

    toggleHeart() {
      if(!this.exercise.vote || this.exercise.vote === -1) {
        this.$set(this.exercise, 'vote', 1)
        axios.post(`/exercises/${this.exercise.id}/upvote`)
      } else {
        this.$set(this.exercise, 'vote', -1)
        axios.post(`/exercises/${this.exercise.id}/downvote`)
      }
    },
  },

  computed: {
    backgroundImage() {
      return `background-image:linear-gradient(180deg, rgba(57, 119, 175, 0) 7.23%, #1F609C 86.48%), url('${this.exercise.thumbnail_url}');`
    },
  }
}
</script>
