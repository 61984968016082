<template>
  <ion-page>
    <ion-content>
      <div class="bg h-24 flex justify-end items-start p-5">
        <button @click="closeModal" class="text-white">
          <XIcon class="w-8 h-8"/>
        </button>
      </div>
      <RoundedBigCard class="-mt-8 relative z-10 overflow-hidden">
        <template slot="content">
          <template v-if="! showFeedback">
            <div class="absolute z-0 inset-0">
              <img src="/images/you_did_it.gif" class="w-full opacity-25" alt="">
            </div>
            <div class="relative z-10">
              <!--You did it-->
              <div class="flex flex-col items-center hlg:mt-16 mb-10">
                <span class="rounded-full h-20 w-20 bg-white shadow-2xl block flex items-center justify-center mb-4">
                  <TickIcon class="w-10 h-10 text-customOrange"/>
                </span>
                <h2>You did it!</h2>
              </div>
              <!--Slider-->
              <flickity class="mb-20" ref="flickity" :options="flickityOptions">
                <!-- <PointsCard :points="points" /> -->
                <MinutesCard :minutes="Math.round((seconds || 60)/60)" />
                <StreakCard :day-streaks="stats ? stats.actual_day_streaks : 1"/>
              </flickity>
              <!--Button-->
              <button @click="showFeedback = true" class="btn-primary relative z-10 text-base text-white bg-lightPurple uppercase w-full">
                Next
              </button>

              <div v-if="doAnother" class="mt-8 grid grid-cols-2 gap-4">
                <button @click.prevent="repeatExercise" class="w-full uppercase font-bold text-lightPurple">
                  Repeat Routine
                </button>

                <button @click.prevent="newExercise" class="w-full uppercase font-bold text-lightPurple">
                  New Exercise
                </button>
              </div>
            </div>
          </template>

          <template v-else>
            <FeedbackCard />
          </template>
        </template>
      </RoundedBigCard>
    </ion-content>
  </ion-page>
</template>

<script>
import Flickity from 'vue-flickity';

import {EventBus} from "@/utilities/event-bus"
import RoundedBigCard from "@/components/shared/RoundedBigCard";
import XIcon from "@/components/svg/XIcon";
import TickIcon from "@/components/svg/TickIcon";
// import PointsCard from "@/components/shared/modals/ProgressModal/partials/PointsCard";
import StreakCard from "@/components/shared/modals/ProgressModal/partials/StreakCard";
import MinutesCard from "@/components/shared/modals/ProgressModal/partials/MinutesCard";
import FeedbackCard from "@/components/shared/modals/ProgressModal/partials/FeedbackCard";
import BadgeAwardModal from "@/components/shared/modals/BadgeAwardModal";

export default {
  name: "ProgressModal",
  
  components: {
    MinutesCard,
    StreakCard,
    TickIcon,
    XIcon,
    RoundedBigCard,
    Flickity,
    FeedbackCard
  },

  mounted: async function() {
    setTimeout(() => {
      this.$refs.flickity.resize();
    }, 100);

    await this.fetchStats()
  },

  data: () => ({
    showFeedback: false,

    feedback: null,

    notes: null,

    flickityOptions: {
      imagesLoaded: true,
      initialIndex: 0,
      prevNextButtons: false,
      pageDots: true,
      wrapAround: false,
    }
  }),

  computed: {
    stats() {
      return this.$store.state.auth.stats
    },

    newLevel() {
      return this.$store.state.auth.newLevel
    },
  },

  watch: {
    newLevel(newLevel) {
      if (! newLevel) {
        return
      }

      this.openBadgeAwardModal()
    }
  },

  methods: {
    closeModal() {
      return this.$ionic.modalController.dismiss();
    },

    repeatExercise() {
      this.$ionic.modalController.dismiss()
      EventBus.$emit('openWorkoutModal', true)
    },

    newExercise() {
      this.$store.dispatch('workout/fetchWorkout', {
        type: 'single exercise',
        fresh: true,
      })

      this.$ionic.modalController.dismiss()
      EventBus.$emit('openWorkoutModal')
    },

    fetchStats() {
      this.$store.dispatch('auth/fetchStats')
    },

    openBadgeAwardModal() {
      return this.$ionic.modalController
        .create({
          component: BadgeAwardModal,
          componentProps: {
            parent: this.$root,
            data: {
              stats: this.stats
            },
          },
        })
        .then((modal) => modal.present());
    }
  }
}
</script>

<style>
.flickity-page-dots {
  bottom: -25% !important;
}

.flickity-page-dots .dot {
  width: 14px !important;
  height: 14px !important;
  @apply bg-lightPurple !important;
}
</style>

