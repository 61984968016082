<template>
  <div class="grid grid-cols-2 border-b pb-3 mb-6">
    <!-- Left col-->
    <Counter class="border-r">
      <template slot="name">
        <span class="font-bold text-sm">Current Streak</span>
      </template>
      <template slot="metric">
        <span v-if="stats" class="text-3xl font-black mr-3">{{ currentStreak }}</span>
        <span v-else class="text-3xl font-black mr-3">-</span>
      </template>
      <template slot="icon">
        <ThunderIcon class="text-customOrange"/>
      </template>
    </Counter>
    <!-- Right col-->
    <Counter>
      <template slot="name">
        <span class="font-bold text-sm">Perfect Weeks</span>
      </template>
      <template slot="metric">
        <span v-if="stats" class="text-3xl font-black mr-3">{{ stats.perfect_weeks }}</span>
        <span v-else class="text-3xl font-black mr-3">-</span>
      </template>
      <template slot="icon">
        <SunIcon class="text-customOrange"/>
      </template>
    </Counter>
  </div>
</template>
<script>
import SunIcon from "../../svg/SunIcon"
import Counter from "@/components/home/ProgressCounter/partials/Counter";
import ThunderIcon from "@/components/svg/ThunderIcon";

export default {
  name: 'ProgressCounter',
  props: {
    stats: Object,
  },
  components: {ThunderIcon, Counter, SunIcon},
  computed: {
    currentStreak() {
      try {
        let currentStreak = 0;
        
        var now = new Date();
        var currentWeekDate = now.getDay();

        let reset = false;

        this.stats.week_progress.forEach((stat) => {
          if(stat.weekday <= currentWeekDate) {
            if(stat.workout.completed === true) {
              if(reset === true) {
                currentStreak = 1;
                reset = false;
              } else {
                currentStreak++;
              }
            } else {
              if(reset === true) {
                currentStreak = 0;
                reset = false;
              } else {
                reset = true;
              }
            }
          }
        });

        return currentStreak;
      } catch(ex) {
        return 0;
      }
    }
  }
}
</script>
