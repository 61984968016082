<template>
  <div
    class="absolute bg-white rounded-xl mt-32 pt-10 pb-5  px-8 shadow-lg  mx-6 z-20 ">
    <div class=" h-full">
      <h1 class=" text-darkViolet font-bold text-2.5xl mb-6">
        {{ exercise.title }}
      </h1>
      <p class="text-sm text-darkViolet mb-10 h-36 overflow-scroll" v-html="exercise.body"></p>

      <button @click.prevent="closeModal"
        class=" w-full">
        <ButtonModals />
      </button>
    </div>

  </div>
</template>

<script>
import ButtonModals from "@/components/shared/ButtonModals.vue";
export default {
  name: "InformationModal",

  components: {
    ButtonModals,
  },
  props: {
    start: {
      default: false,
      type: Boolean,
    },

    exercise: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    closeModal() {
      this.$emit("closeInfo");
    },
  },
};
</script>
