<template>
  <div>
    <div class="h-56 bg">
      <!--Header-->
      <ModalHeader :start="workout.start" :back="back"
        :close-modal="closeModal" />
    </div>
    <!-- Content-->
    <div class="bg-smoke h-full relative">

      <!-- Video-->
      <VideoComponent :start="workout.start" :showShortVideo="showShortVideo"
        :exercise="currentExercise" :next-exercise="nextExercise"
        :key="`exercise-${currentExercise.id}`" @openInfo="showInfo" />

      <ExerciseDetails :exercise="currentExercise" :next-exercise="nextExercise"
        :workout="workout" @start-count="onStartCount"
        @disliked="showDislikeModal" @liked="showLikeModal" />
    </div>
    <!-- Modals-->
    <div class="absolute w-full flex justify-center">
      <LikeModal :exercise="currentExercise" @closeLike="hideLike" v-show="likeModal" />
      <DislikeModal @closeDislike="hideDislike" v-show="dislikeModal" />
      <InformationModal :exercise="currentExercise" @closeInfo="hideInfo"
        v-show="infoModal" />
    </div>
    <div v-show="this.showShortVideo" class="bg-smoke w-full pb-4">
     <button class="w-full text-center block mt-5 font-bold text-lightPurple"
        @click="skipExercise">skip</button>
    </div>
  </div>

</template>

<script>
import { Insomnia } from "@ionic-native/insomnia";
import { Plugins } from "@capacitor/core";
import { EventBus } from "@/utilities/event-bus";
import ExerciseDetails from "@/components/shared/modals/WorkoutModal/partials/ExerciseDetails";
import ModalHeader from "@/components/shared/modals/WorkoutModal/partials/ModalHeader";
import VideoComponent from "@/components/shared/modals/WorkoutModal/partials/VideoComponent";
import ProgressModal from "@/components/shared/modals/ProgressModal/index";
import LikeModal from "@/components/shared/modals/LikeModal/index.vue";
import DislikeModal from "@/components/shared/modals/DislikeModal/index.vue";
import InformationModal from "@/components/shared/modals/InformationModal/index.vue";

export default {
  name: "WorkoutModal",

  components: {
    ModalHeader,
    ExerciseDetails,
    VideoComponent,
    LikeModal,
    DislikeModal,
    InformationModal,
  },

  data() {
    return {
      seconds: 0,
      insomnia: null,
      likeModal: false,
      dislikeModal: false,
      infoModal: false,
      showShortVideo: false,
      working: false,
    };
  },

  mounted: function () {
    this.insomnia = Insomnia;

    if (this.start === true) {
      this.startWorkout();
    }

    EventBus.$on("startWorkout", () => {
      this.startWorkout();
    });

    EventBus.$on("endExercise", () => {
      this.$store.dispatch("auth/storeUserDfgs", { dfgs: 1 });
      this.handleNextExercise();
    });

    EventBus.$on("recordMovement", (seconds) => {
      this.seconds = this.seconds + seconds;

      this.$store.dispatch("auth/updateStats", { seconds });
    });

    try {
      if (Plugins.App.hasListeners("appStateChange")) return;
    } catch (error) {
      this.enableAppStateListener();
    }
  },

  methods: {
    skipExercise() {
      this.handleNextRepsExercise();
    },

    showInfo() {
      this.infoModal = !this.infoModal;
    },
    hideInfo() {
      this.infoModal = false;
    },
    showLikeModal() {
      this.likeModal = !this.likeModal;
    },
    hideLike() {
      this.likeModal = false;
    },
    showDislikeModal() {
      this.dislikeModal = !this.dislikeModal;
    },
    hideDislike() {
      this.dislikeModal = false;
    },

    onStartCount: function () {
      this.showShortVideo = true;
    },

    closeModal() {
      this.allowScreenSleep();
      this.workout.start = false;
      this.workout.currentSet = 1;
      this.workout.currentExerciseIndex = 0;

      return this.$ionic.modalController.dismiss();
    },

    startWorkout() {
      this.keepScreenAwake();

      this.workout.start = true;

      this.$store.dispatch("workout/storeWorkout", {
        type_id: this.workout.type.id,
        exercises: this.workout.exercises,
      });
    },

    async finishWorkout() {
      this.allowScreenSleep();

      await this.$store.dispatch("workout/updateWorkout", {
        completed: true,
      });

      await this.$store.dispatch("auth/updateStats", {
        points: 20,
      });

      this.openProgressModal();
      this.closeModal();
    },

    stopWorkout() {
      this.allowScreenSleep();
      this.workout.currentSet = 1;
      this.workout.start = false;
    },

    keepScreenAwake() {
      this.insomnia.keepAwake();
    },

    allowScreenSleep() {
      this.insomnia.allowSleepAgain();
    },

    back() {
      this.stopWorkout();
    },

    openProgressModal() {
      return this.$ionic.modalController
        .create({
          component: ProgressModal,
          componentProps: {
            parent: this.$root,
            data: {
              points: 20,
              seconds: this.seconds,
              doAnother: this.workout.type.name == "Single Exercise",
            },
          },
        })
        .then((modal) => modal.present());
    },

    handleNextExercise() {
      if (this.workout.type.name == "Tabatta") {
        return this.handleNextTabattaExercise();
      }

      this.handleNextRepsExercise();
    },

    handleNextTabattaExercise() {
      if (!this.hasMoreSets && !this.hasMoreExercises) {
        console.log("tabatta: no more exercises or sets -> finish");

        this.markExerciseAsCompleted(this.currentExercise.id);
        this.finishWorkout();

        return;
      }

      if (!this.hasMoreSets && this.hasMoreExercises) {
        console.log(
          "tabatta: no more sets, but more exercises -> reseting sets and advancing the exercise index"
        );

        this.markExerciseAsCompleted(this.currentExercise.id);

        // reset current set index
        this.workout.currentSet = 1;

        // advance exercise index
        this.workout.currentExerciseIndex++;

        return;
      }

      console.log(
        "tabatta: we have more sets or more exercises -> advancing sets"
      );
      // advance the current set
      this.workout.currentSet++;
    },

    handleNextRepsExercise() {
      if (!this.hasMoreSets && !this.hasMoreExercises) {
        console.log("reps: no more exercises or sets -> finish");

        this.markExerciseAsCompleted(this.currentExercise.id);
        this.finishWorkout();

        return;
      }

      if (this.hasMoreSets && !this.hasMoreExercises) {
        console.log(
          "reps: we have more sets but no exercise -> advancing set and reseting exersice index"
        );
        // reset current exercise index
        this.workout.currentExerciseIndex = 0;
        this.workout.currentSet++;

        return;
      }

      console.log(
        "reps: we have more sets or more exercises -> advancing exercises"
      );

      this.markExerciseAsCompleted(this.currentExercise.id);
      this.workout.currentExerciseIndex++;
    },

    markExerciseAsCompleted(id) {
      this.$store.dispatch("workout/completeExercise", id);
    },

    enableAppStateListener() {
      Plugins.App.addListener("appStateChange", (state) => {
        if (state.isActive) return;

        this.stopWorkout();
      });
    },
  },

  destroyed: function () {
    EventBus.$off("startWorkout");
    EventBus.$off("endExercise");
    EventBus.$off("recordMovement");
    this.stopWorkout();
  },

  computed: {
    currentExercise() {
      return this.workout.exercises[this.workout.currentExerciseIndex];
    },

    nextExercise() {
      if (!this.workout) {
        return;
      }

      return (
        this.workout.exercises[this.workout.currentExerciseIndex + 1] || null
      );
    },

    hasMoreSets() {
      return this.workout.currentSet < this.workout.params.sets;
    },

    hasMoreExercises() {
      return (
        this.workout.currentExerciseIndex < this.workout.exercises.length - 1
      );
    },
  },
};
</script>
