<template>
  <div class="absolute bg-white rounded-xl mt-32 pt-5 pb-8 px-2 shadow-lg h-44 mx-16 z-20">
    <div class="flex w-full justify-end px-5 mb-5">
      <button @click.prevent="closeModal">
        <XIcon class="text-lightPurple" />
      </button>
    </div>

    <div class="flex flex-col justify-center items-center mb-6">
      <DislikeIcon class="mb-8 h-10 w-10 text-lightPurple" />
      <p class=" font-light text-darkPurple px-3 text-center">This excercise has been been disliked. You will never have to see it again. </p>
    </div>
  </div>
</template>

<script>
import DislikeIcon from "@/components/svg/DislikeIcon.vue";
import XIcon from "@/components/svg/XIcon.vue";

export default {
  name: "DsilikeModal",

  components: {
    DislikeIcon,
    XIcon,
  },

  methods: {
      closeModal() {
          this.$emit("closeDislike");
      }
  },
}
</script>
