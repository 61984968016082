<template>
  <div
    class="absolute bg-white rounded-xl mt-32 pt-5 pb-8 px-2 shadow-lg h-44 mx-10 z-20">
    <div class="flex w-full justify-end px-5 mb-5">
      <button @click.prevent="closeModal">
        <XIcon class="text-lightPurple" />
      </button>

    </div>

    <div class="flex flex-col justify-center items-center mb-6">
      <LikeIcon class="mb-8 h-10 w-10 text-lightPurple" />
      <p class=" font-light text-darkPurple px-10 text-center">This excercise
        has been been liked. And you will see more </p>
    </div>

    <div class="mt-8 w-full px-4 justify-center flex">
      <div class="w-8 h-8" @click.prevent="shareViaTwitter">
        <TwitterIcon fill="#7E6A97"/>
      </div>

      <div class="w-8 h-8 mx-2" @click.prevent="shareViaFacebook">
        <FacebookBadgesIcon fill="#7E6A97"/>
      </div>

      <div class="w-8 h-8" @click.prevent="shareViaInstagram">
        <InstagramIcon fill="#7E6A97"/>
      </div>
    </div>

  </div>
</template>

<script>
import LikeIcon from "@/components/svg/LikeIcon.vue";
import XIcon from "@/components/svg/XIcon.vue";
import TwitterIcon from "@/components/svg/TwitterIcon.vue";
import FacebookBadgesIcon from "@/components/svg/FacebookBadgesIcon.vue";
import InstagramIcon from "@/components/svg/InstagramIcon.vue";
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { mapState, mapActions } from 'vuex';
import { STORE_CELEBRATION } from '@/store/types/actions'


export default {
  name: "LikeModal",

  components: {
    LikeIcon,
    XIcon,
    TwitterIcon,
    FacebookBadgesIcon,
    InstagramIcon
  },

  data: () => ({
    socialSharing: null
  }),

  props: {
    exercise: Object,
  },

  computed: {
    ...mapState({
      user: state => state.auth.user
    })
  },

  methods: {

    ...mapActions({
        sendCelebration: `auth/${STORE_CELEBRATION}`
    }),

    closeModal() {
      this.$emit("closeLike");
    },

    onSharingSuccess(platform){
      this.sendCelebration(platform)
      .then(() => {
        this.closeModal()
      })
      .catch(error => {
        console.log(error);
      })
    },

    onSharingError(ex){
      console.log('Failed', ex)
    },

    shareViaTwitter(){
      this.socialSharing = new SocialSharing()
      const message = `${this.user.name} just completed an exercise and Daily Feel Goods! \n Try this exercise and others for free!`;

      this.socialSharing.shareViaTwitter(message, this.exercise.thumbnail_url, null)
      .then(() => {
        this.onSharingSuccess('twitter')
      }).catch((error) => {
        this.onSharingError(error);
      });
    },

    shareViaFacebook(){
      this.socialSharing = new SocialSharing()
      const message = `${this.user.name} just completed an exercise and Daily Feel Goods! \n Try this exercise and others for free!`;
      
      this.socialSharing.shareViaFacebook(message, null, null)
      .then(() => {
        this.onSharingSuccess('facebook')
      }).catch((error) => {
        this.onSharingError(error);
      });
    },

    shareViaInstagram(){
      this.socialSharing = new SocialSharing()
      const message = `${this.user.name} just completed an exercise and Daily Feel Goods! \n Try this exercise and others for free!`;

      this.socialSharing.shareViaInstagram(message, null, null)
      .then(() => {
        this.onSharingSuccess('instagram')
      }).catch((error) => {
        this.onSharingError(error);
      });
    },
  }
};
</script>
<style>
.blur {
  width: 100%;
  height: 100%;
  background-size: cover;
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -ms-filter: blur(4px);
  -o-filter: blur(4px);
  filter: blur(4px);
}
</style>