<template>
  <div class="grid grid-cols-7">
    <div v-for="(progress, index) in weekProgress" :key="index" class="flex flex-col items-center">
      <span class="text-sm" :class="{'font-bold': currentWeekday == progress.weekday}">
        {{ progress.day }}
      </span>
      <Status 
        :progress="progress" 
        :key="`progress-${progress.workout.completed}-${progress.workout.finished_exercises}-${progress.workout.exercises}`"
      />
    </div>
  </div>
</template>
<script>
import Status from "@/components/home/WeekProgress/partials/Status";

export default {
    name: 'WeekProgress',
    components: {Status},
    props: {
      weekProgress: Array
    },

    computed: {
      currentWeekday() {
        return (new Date()).getDay()
      },
    }
  }
</script>

