<template>
  <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-8 w-8">
<path d="M13.7633 12.905C13.0981 12.905 12.539 13.4372 12.539 14.1294C12.539 14.7945 13.0711 15.3537 13.7633 15.3537C14.4555 15.3537 14.9876 14.8215 14.9876 14.1294C14.9876 13.4642 14.4284 12.905 13.7633 12.905ZM13.7633 15.9118C13.0981 15.9118 12.566 16.4439 12.566 17.1091V24.0268C12.566 24.6919 13.0981 25.224 13.7633 25.224C14.4284 25.224 14.9606 24.6919 14.9606 24.0268V17.1091C14.9606 16.4439 14.4284 15.9118 13.7633 15.9118ZM13.7633 7.05164C7.13882 7.05164 1.76328 12.4259 1.76328 19.0516C1.76328 25.6761 7.13856 31.0506 13.7633 31.0506C20.388 31.0506 25.7633 25.6764 25.7633 19.0506C25.7633 12.4261 20.388 7.05164 13.7633 7.05164ZM13.7633 29.1088C8.20308 29.1088 3.70581 24.6129 3.70581 19.0514C3.70581 13.4901 8.20281 9.02104 13.7633 9.02104C19.3237 9.02104 23.8207 13.517 23.8207 19.0514C23.8207 24.5858 19.3237 29.1088 13.7633 29.1088Z" fill="white"/>
</svg>

</template>

<script>
export default {
name:"InformationIcon"
}
</script>
