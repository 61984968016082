<template>
  <div class="flex w-full justify-end px-4 pt-4 hlg:pt-10 relative z-30">
    <button @click="closeModal" class="text-white">
      <XIcon class="w-8 h-8"/>
    </button>
  </div>
</template>
<script>
import XIcon from "@/components/svg/XIcon"

export default {
  name: 'ModalHeader',
  
  components: {
    XIcon
  },

  props: {
    closeModal: Function,
    back: Function,
    start: Boolean
  }
}
</script>
