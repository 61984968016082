<template>
  <div>
    <div v-if="!feedbackSent" class="relative z-10 flex flex-col items-center hlg:mt-16 mb-10 h-full">
      <template v-if="!showFeedbackForm">
        <div>
          <div class="flex flex-col items-center">
            <h1 class="font-normal text-4xl">Tell us</h1> 
            <h2 class="text-4xl">How you feel</h2>
          </div>

          <div class="w-full mt-12">
            <div class="grid grid-cols-3 gap-x-2">
              <button @click.prevent="submitFeedback(1)" class="flex flex-col items-center">
                <span class="inline-block rounded-full w-20 h-20 bg-white shadow flex items-center justify-center text-4xl">
                  😓
                </span>
                <span class="mt-2 text-sm font-semibold">Ehh, so-so</span>
              </button>

              <button @click.prevent="submitFeedback(2)" class="flex flex-col items-center">
                <span class="inline-block rounded-full w-20 h-20 bg-white shadow flex items-center justify-center text-4xl">
                  🙂
                </span>
                <span class="mt-2 text-sm font-semibold">
                  About the same<br><span class="font-normal">(but looking forward to the next one)</span>
                </span>
              </button>

              <button @click.prevent="submitFeedback(3)" class="flex flex-col items-center">
                <span class="inline-block rounded-full w-20 h-20 bg-white shadow flex items-center justify-center text-4xl">
                  💆
                </span>
                <span class="mt-2 text-sm font-semibold">
                  Clear headed
                </span>
              </button>
            </div>
            <div class="grid grid-cols-2 gap-x-2 mt-6">
              <button @click.prevent="submitFeedback(4)" class="flex flex-col items-center">
                <span class="inline-block rounded-full w-20 h-20 bg-white shadow flex items-center justify-center text-4xl">
                  🙌
                </span>
                <span class="mt-2 text-sm font-semibold">
                  My body hurts less
                </span>
              </button>

              <button @click.prevent="submitFeedback(5)" class="flex flex-col items-center">
                <span class="inline-block rounded-full w-20 h-20 bg-white shadow flex items-center justify-center text-4xl">
                  💪
                </span>
                <span class="mt-2 text-sm font-semibold">
                  Energized
                </span>
              </button>
            </div>
          </div>

          <div class="fixed bottom-0 inset-x-0 h-20 flex items-center justify-center">
              <button @click.prevent="closeModal" class="font-bold text0-lightPurple tracking-widest">SKIP</button>
          </div>
        </div>
      </template>

      <template v-else>
        <ValidationObserver ref="signinForm" v-slot="{ handleSubmit }">
          <form class="w-full" @submit.prevent="handleSubmit(onFeedbackContentSubmit)">
            <div class="w-full  flex flex-col items-center mb-4 md:mb-2">
              <span class="inline-block rounded-full w-20 h-20 bg-white shadow flex items-center justify-center text-4xl mb-2">
                😓
              </span>
              <span class="text-sm font-semibold">Ehh, so-so</span>
            </div>

            <div class="w-full flex flex-col items-center mb-8 md:mb-2">
              <span class="text-3xl font-semibold">So sorry to hear that!</span>
              <span class="text-base ">Anything we should know about?</span>
            </div>

            <div class="w-full justify-center mb-8 md:mb-2 hidden md:flex">
              <ValidationProvider mode="eager" rules="required|max:255" vid="content" name="Content" v-slot="{ errors }"  class="w-full">
                <textarea class="form-input rounded-lg" rows="6"></textarea>
                <p v-show="errors.length" class="w-full text-red-600 mt-1 text-sm"> 
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </div>

            <div class="w-full flex justify-center mb-8 md:mb-2 flex md:hidden">
              <ValidationProvider mode="eager" rules="required|max:255" vid="content" name="Content" v-slot="{ errors }" class="w-full">
                <textarea v-model="feedbackContent" class="form-input rounded-lg text-lg" rows="10" placeholder="Tell us..."></textarea>
                <p v-show="errors.length" class="w-full text-red-600 mt-1 text-sm"> 
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </div>

            <div class="fixed bottom-0 inset-x-0 h-20 flex items-center justify-between px-20">
              <button @click.prevent="showFeedbackForm = false" class="font-bold text0-lightPurple tracking-widest">BACK</button>

              <button class="w-40 btn-primary text-base text-white bg-lightPurple" :class="{'opacity-75': loading}" type="submit" :disabled="loading">
                <span class="w-full text-gray-100 text-center">
                  {{ loading ? 'LOADING..' : 'SUBMIT' }}
                </span>
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </div>

    <div v-if="feedbackSent" class="flex flex-col items-center hlg:mt-16 mb-10 h-full">
      <div class="flex flex-col items-center justify-center hlg:mt-16 mb-10">
        <span class="rounded-full h-20 w-20 bg-white shadow-2xl block flex items-center justify-center mb-4">
          <TickIcon class="w-10 h-10 text-customOrange"/>
        </span>
        <h2 class="text-center leading-snug">Thank you for your feedback!</h2>
      </div>
      <div class="fixed bottom-0 inset-x-0 h-20 flex items-center justify-center px-6">
        <button @click.prevent="closeModal" class="btn-primary relative z-10 text-base text-white bg-lightPurple uppercase w-full">
          Close
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { Plugins } from "@capacitor/core";
  import { mapActions } from 'vuex';

  import TickIcon from "@/components/svg/TickIcon"
  
  const { NativeAudio } = Plugins;
  
  export default {
    components: {TickIcon},

    data() {
      return {
        feedbackSent: false,
        showFeedbackForm: false,
        feedbackContent: '',
        loading: false,
      }
    },

    methods: {
      ...mapActions({
        updateWorkout: 'workout/updateWorkout',
      }),

      closeModal() {
        try {
          NativeAudio.play({
            assetId: "when-you-exit-confeti-screen",
          });
        } catch (ex) {
          console.log(ex);
        }

        return this.$ionic.modalController.dismiss();
      },

      submitFeedback(feedback) {
        if(feedback === 1) {
          this.showFeedbackForm = true;
        } else {
          this.$store.dispatch('workout/updateWorkout', { liked: feedback })
          this.feedbackSent = true
        }
      },

      onFeedbackContentSubmit: async function() {
        try {
          this.loading = true;
          await this.updateWorkout({ liked: 1, feedback_content: this.feedbackContent });
          this.feedbackSent = true;
        } catch (ex) {
          console.log(ex);
        } finally {
          this.loading = false;
        }
      }
    }
  }
</script>
