<template>
  <div class="flex justify-between items-center w-full mb-6">
    <!--LEFT-->
    <!--Dislike button-->
    <button v-if="exercise.vote !== -1" type="button" @click.prevent="downvote"
            class="bg-white text-lightPurple rounded-r-full h-15 pl-3 pr-6 py-3 flex justify-center items-center shadow-xl ">
      <DislikeIcon/>
    </button>
    <button v-else-if="exercise.vote === -1" type="button" @click.prevent="clearVote"
            class="bg-lightPurple text-lightPurple rounded-r-full h-15 pl-3 pr-6 py-3 flex justify-center items-center shadow-xl ">
      <DislikeIcon/>
    </button>
    <!--CENTER-->
    <button @click="startExercise"
            class="btn-primary bg-orange-gradient text-white shadow-orange w-1/2 leading-9"
    >
      Start
    </button>
    <!--Like button-->
    <button v-if="exercise.vote !== 1" type="button" @click.prevent="upvote"
            class="bg-white text-lightPurple rounded-l-full h-15 pr-3 pl-6 py-3 flex justify-center items-center shadow-xl">
      <LikeIcon/>
    </button>
    <button v-else-if="exercise.vote === 1" type="button" @click.prevent="clearVote"
            class="bg-lightPurple text-lightPurple rounded-l-full h-15 pr-3 pl-6 py-3 flex justify-center items-center shadow-xl">
      <LikeIcon/>
    </button>
  </div>
</template>
<script>
import axios from 'axios'
import {EventBus} from "@/utilities/event-bus"
import DislikeIcon from "@/components/svg/DislikeIcon"
import LikeIcon from "@/components/svg/LikeIcon"

export default {
  name: 'ExerciseActions',

  props: {
    workout: Object,
    exercise: Object,
  },

  components: {
    DislikeIcon,
    LikeIcon
  },
  
  methods: {
    startExercise() {
      EventBus.$emit('startWorkout')
    },

    upvote() {
      this.exercise.vote = 1
      axios.post(`/exercises/${this.exercise.id}/upvote`)
    },

    downvote() {
      this.exercise.vote = -1
      axios.post(`/exercises/${this.exercise.id}/downvote`)
    },

    clearVote() {
      this.exercise.vote = null
      axios.post(`/exercises/${this.exercise.id}/clear-vote`)
    }
  }
}
</script>
