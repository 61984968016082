<template>
  <svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
      <path
        d="M2.91669 2.59063C2.91669 0.997572 4.68839 0.0439243 6.01811 0.921239L22.5531 11.8306C23.7512 12.6211 23.7512 14.3789 22.5531 15.1694L6.01811 26.0788C4.68839 26.9561 2.91669 26.0024 2.91669 24.4094V2.59063Z"
        fill="currentColor"/>
    </g>
    <defs>
      <filter id="filter0_d" x="0.916687" y="0.58728" width="24.535" height="29.8254" filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
      </filter>
    </defs>
  </svg>
</template>

<script>
  export default {
    name: "PlayIcon"
  }
</script>

<style scoped>

</style>
