<template>
  <div class="bg h-24 hlg:h-32 flex justify-end items-start px-4 pt-4 hlg:pt-10">
    <button @click="closeModal" class="text-white">
      <XIcon class="w-8 h-8"/>
    </button>
  </div>
</template>
<script>
import XIcon from "@/components/svg/XIcon"

export default {
  name: 'ModalHeader',
  components: {XIcon},
  props: {
    closeModal: {}
  }
}
</script>
