<template>
  <Button @click="openModal" v-if="currentRoute === 'home'"
          class="shadow-orange absolute left-0 right-0 bottom-0 mx-auto rounded-full w-16 h-16 flex justify-center items-center z-20 text-white mb-20 hlg:mb-30 bg-gradient-to-r from-customOrange to-customRed">
    <PlayIcon class="h-6 w-6 pl-px"/>
  </Button>
</template>
<script>
import { mapState } from 'vuex';

import PlayIcon from "../svg/PlayIcon"
import WorkoutModal from "@/components/shared/modals/WorkoutModal/index";

export default {
  name: 'PlayButton',

  components: {
    PlayIcon
  },

  methods: {
    openModal() {
      return this.$ionic.modalController
        .create({
          component: WorkoutModal,
          swipeToClose: true,
          componentProps: {
            parent: this.$root,
            data: {
              workout: this.workout,
              start: true,
            },
          }
        })
        .then(modal => modal.present())
    },
  },

  computed: {
    ...mapState({
      workout: state => state.workout
    }),

    currentRoute() {
      return this.$route.name;
    },
  }
}
</script>
