<template>
  <svg viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.99997 14.5C8.8903 14.5006 8.78158 14.4796 8.68005 14.4382C8.57852 14.3967 8.48617 14.3356 8.40831 14.2583L1.93331 7.77501C1.12111 6.95433 0.665527 5.84632 0.665527 4.69168C0.665527 3.53704 1.12111 2.42903 1.93331 1.60835C2.75186 0.792106 3.86067 0.33374 5.01664 0.33374C6.17262 0.33374 7.28142 0.792106 8.09997 1.60835L8.99997 2.50835L9.89997 1.60835C10.7185 0.792106 11.8273 0.33374 12.9833 0.33374C14.1393 0.33374 15.2481 0.792106 16.0666 1.60835C16.8788 2.42903 17.3344 3.53704 17.3344 4.69168C17.3344 5.84632 16.8788 6.95433 16.0666 7.77501L9.59164 14.2583C9.51377 14.3356 9.42143 14.3967 9.3199 14.4382C9.21836 14.4796 9.10965 14.5006 8.99997 14.5ZM5.01664 2.00001C4.66387 1.99842 4.31429 2.06683 3.98815 2.20129C3.66201 2.33576 3.36578 2.53359 3.11664 2.78335C2.61336 3.28928 2.33084 3.97389 2.33084 4.68751C2.33084 5.40114 2.61336 6.08575 3.11664 6.59168L8.99997 12.4833L14.8833 6.59168C15.3866 6.08575 15.6691 5.40114 15.6691 4.68751C15.6691 3.97389 15.3866 3.28928 14.8833 2.78335C14.3697 2.2981 13.6899 2.02776 12.9833 2.02776C12.2767 2.02776 11.5969 2.2981 11.0833 2.78335L9.59164 4.28335C9.51417 4.36145 9.422 4.42345 9.32045 4.46576C9.21891 4.50806 9.10998 4.52985 8.99997 4.52985C8.88996 4.52985 8.78104 4.50806 8.67949 4.46576C8.57794 4.42345 8.48578 4.36145 8.40831 4.28335L6.91664 2.78335C6.6675 2.53359 6.37128 2.33576 6.04513 2.20129C5.71899 2.06683 5.36941 1.99842 5.01664 2.00001Z"
      fill="currentColor"/>
  </svg>
</template>

<script>
  export default {
    name: "HeartIcon"
  }
</script>

<style scoped>

</style>
