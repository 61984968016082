<template>
  <div>
    <template v-if="! workout.start">
      <!-- Exercise info-->
      <ExerciseInfo :exercise="exercise" :workout="workout" />

      <div class="absolute bottom-0 inset-x-0 bg-smoke">
        <!-- Actions-->
        <ExerciseActions :exercise="exercise" :workout="workout" />

        <!-- Footer-->
        <NextExercise :exercise="nextExercise" />
      </div>
    </template>

    <template v-else>
      <div class="absolute bottom-0 z-10 w-full mb-2">
        <div class="flex items-center ion-justify-content-between">
          <!--Dislike button-->
          <button v-if="exercise.vote !== -1" type="button"
            @click.prevent="downvote"
            class="bg-white text-lightPurple rounded-r-full h-15 pl-3 pr-6 py-3 flex justify-center items-center shadow-xl ">
            <DislikeIcon />
          </button>
          <button v-else-if="exercise.vote === -1" type="button"
            @click.prevent="clearVote"
            class="bg-lightPurple text-lightPurple rounded-r-full h-15 pl-3 pr-6 py-3 flex justify-center items-center shadow-xl ">
            <DislikeIcon />
          </button>

          <RepsCountDown :type="workout.type.name" :reps="reps" :rest="workout.params.rest" :symmetry="exercise.symmetry" @start-count="$emit('start-count')" :key="`reps-count-${countKey}`" />
          <!--Like button-->
          <button v-if="exercise.vote !== 1" type="button"
            @click.prevent="upvote"
            class="bg-white text-lightPurple rounded-l-full h-15 pr-3 pl-6 py-3 flex justify-center items-center shadow-xl">
            <LikeIcon />
          </button>
          <button v-else-if="exercise.vote === 1" type="button"
            @click.prevent="clearVote"
            class="bg-lightPurple text-lightPurple rounded-l-full h-15 pr-3 pl-6 py-3 flex justify-center items-center shadow-xl">
            <LikeIcon />
          </button>

        </div>
      </div>
      <!-- <div class="absolute inset-x-0 bottom-0">
        set: {{ workout.currentSet }} / exerciseIndex: {{ workout.currentExerciseIndex }}
      </div> -->
    </template>
  </div>
</template>

<script>
import axios from "axios";
import { EventBus } from "@/utilities/event-bus";
import ExerciseActions from "@/components/shared/modals/WorkoutModal/partials/ExerciseActions";
import ExerciseInfo from "@/components/shared/modals/WorkoutModal/partials/ExerciseInfo";
import NextExercise from "@/components/shared/modals/WorkoutModal/partials/NextExercise";
import RepsCountDown from "@/components/shared/modals/WorkoutModal/partials/RepsCountDown";
import DislikeIcon from "@/components/svg/DislikeIcon";
import LikeIcon from "@/components/svg/LikeIcon";
// import TimeCountDown from "@/components/shared/modals/WorkoutModal/partials/TimeCountDown"
// import {EventBus} from "@/utilities/event-bus"
export default {
  props: {
    exercise: Object,
    nextExercise: Object,
    workout: Object,
  },

  components: {
    ExerciseInfo,
    ExerciseActions,
    NextExercise,
    RepsCountDown,
    DislikeIcon,
    LikeIcon,
  },
  methods: {
    startExercise() {
      EventBus.$emit("startWorkout");
    },

    upvote() {
      this.exercise.vote = 1;
      axios.post(`/exercises/${this.exercise.id}/upvote`);
      this.$emit("liked");
    },

    downvote() {
      this.exercise.vote = -1;
      axios.post(`/exercises/${this.exercise.id}/downvote`);
      this.$emit("disliked");
    },

    clearVote() {
      this.exercise.vote = null;
      axios.post(`/exercises/${this.exercise.id}/clear-vote`);
    },
  },
  computed: {
    reps() {
      if (this.workout.type.name == "Tabatta") {
        return this.workout.params.duration;
      }

      return this.workout.params.reps[this.exercise.symmetry || "bilateral"];
    },

    countKey() {
      return `${this.workout.currentSet}-${this.workout.currentExerciseIndex}`;
    },
  },
};
</script>
